import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { JwtHelperService } from '@auth0/angular-jwt';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  user = new BehaviorSubject(null);
  apiPath = 'https://api.activeemails.com';
  // apiPath = 'http://localhost:3000';
  constructor(private http: HttpClient, private jwt: JwtHelperService) {
    if (this.isAuthenticated()) {
      this.user.next(this.getUser());
    }
  }

  isAuthenticated() {
    const token = this.getToken();
    return token && !this.jwt.isTokenExpired(token);
  }

  currentUser() {
    return this.user.getValue();
  }
  login(email, password) {
    return this.http.post<any>(`${this.apiPath}/login`, { email, password });
  }
  requestReset(email) {
    return this.http.post<any>(`${this.apiPath}/reset`, { email });
  }
  changePassword(data) {
    return this.http.post<any>(`${this.apiPath}/resetPassword`, data);
  }
  verifyLink(data) {
    return this.http.post<any>(`${this.apiPath}/verifyLink`, data);
  }

  getUser() {
    const user = this.jwt.decodeToken(this.getToken());
    console.log('user details', user);
    const result = user ? user._doc : null;
    return result;
  }

  getToken() {
    return localStorage.getItem('solutions_jwt_token');
  }
  setToken(token) {
    localStorage.setItem('solutions_jwt_token', token);
    this.user.next(this.getUser());
  }

  logout() {
    localStorage.removeItem('solutions_jwt_token');
    this.user.next(null);
  }
  createUser(data) {
    return this.http.post<any>(`${this.apiPath}/createUser`, data);
  }
  getUsers(data) {
    return this.http.post<any>(`${this.apiPath}/getUsers`, data);
  }
  deleteUser(data) {
    return this.http.post<any>(`${this.apiPath}/deleteUser`, data);
  }
  getStatData(data) {
    return this.http.post<any>(`${this.apiPath}/getStatData`, data);
  }
  getRefData(data) {
    return this.http.post<any>(`${this.apiPath}/getRefData`, data);
  }
  changePasswordUser(data) {
    return this.http.post<any>(`${this.apiPath}/changePassword`, data);
  }
  getUserDetailsById(data) {
    return this.http.post<any>(`${this.apiPath}/getUserById`, data);
  }
  editUser(data) {
    return this.http.post<any>(`${this.apiPath}/editUser`, data);
  }
}
