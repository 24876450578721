import { SideNavInterface } from '../../interfaces/side-nav.type';
export const ROUTES: SideNavInterface[] = [
  {
    path: 'dashboard/client',
    title: 'Client Data',
    iconType: 'nzIcon',
    userType: 'User',
    iconTheme: 'outline',
    icon: 'dashboard',
    submenu: [],
  },
  {
    path: 'dashboard/referral',
    title: 'Referral Data',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    userType: 'User',
    icon: 'dashboard',
    submenu: [],
  },
  {
    path: 'dashboard/clients',
    title: 'Clients',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    userType: 'Super Admin',
    icon: 'user',
    submenu: [],
  },
  {
    path: 'dashboard/new-client',
    title: 'New Client',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    userType: 'Super Admin',
    icon: 'plus',
    submenu: [],
  },
  {
    path: 'dashboard/change-password',
    title: 'Change Password',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    userType: '',
    icon: 'lock',
    submenu: [],
  },
  {
    path: 'dashboard/esp-code',
    title: 'ESP Code',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    userType: 'User',
    icon: 'code',
    submenu: [],
  },
  {
    path: '',
    title: 'Logout',
    iconType: 'nzIcon',
    iconTheme: 'outline',
    userType: '',
    icon: 'logout',
    submenu: [],
  },
];    