import { Component } from '@angular/core';
import { ROUTES } from './side-nav-routes.config';
import { ThemeConstantService } from '../../services/theme-constant.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './side-nav.component.html',
})
export class SideNavComponent {
  public menuItems: any[];
  isFolded: boolean;
  isSideNavDark: boolean;
  isExpand: boolean;
  user;

  constructor(
    private themeService: ThemeConstantService,
    private authService: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isExpandChanges.subscribe(
      (isExpand) => (this.isExpand = isExpand)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );

    this.user = this.authService.getUser();
    this.menuItems = ROUTES.filter(
      (menuItem) =>
        (menuItem.userType == this.user.user_type || menuItem.userType == '') &&
        ((menuItem.path == 'dashboard/client' && this.user.status_data) ||
          (menuItem.path == 'dashboard/referral' && this.user.affiliate) ||
          (menuItem.path != 'dashboard/client' &&
            menuItem.path != 'dashboard/referral' &&
            menuItem.path != 'dashboard/esp-code') ||
          (menuItem.path == 'dashboard/esp-code' && this.user.esp_code))
    );
  }

  closeMobileMenu(): void {
    if (window.innerWidth < 992) {
      this.isFolded = false;
      this.isExpand = !this.isExpand;
      this.themeService.toggleExpand(this.isExpand);
      this.themeService.toggleFold(this.isFolded);
    }
  }
  logout() {
    this.authService.logout();
    this.router.navigateByUrl('/authentication/login');
  }
}
