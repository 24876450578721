import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { AuthService } from 'src/app/core/services/auth.service';
import { ThemeConstantService } from '../../services/theme-constant.service';

@Component({
  selector: 'app-quick-view',
  templateUrl: './quick-view.component.html',
})
export class QuickViewComponent {
  selectedHeaderColor: string;
  isSideNavDark: boolean;
  isFolded: boolean;
  user;
  constructor(
    private themeService: ThemeConstantService,
    private toaster: NzNotificationService,
    private auth: AuthService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.user = this.auth.getUser();
    if (!this.user.esp_code) {
      this.router.navigateByUrl('dashboard/client');
    }
    this.themeService.isMenuFoldedChanges.subscribe(
      (isFolded) => (this.isFolded = isFolded)
    );
    this.themeService.isSideNavDarkChanges.subscribe(
      (isDark) => (this.isSideNavDark = isDark)
    );
    this.themeService.selectedHeaderColor.subscribe(
      (color) => (this.selectedHeaderColor = color)
    );
  }

  changeHeaderColor() {
    this.themeService.changeHeaderColor(this.selectedHeaderColor);
  }

  toggleSideNavDark() {
    this.themeService.toogleSideNavDark(this.isSideNavDark);
  }

  toggleFold() {
    this.themeService.toggleFold(this.isFolded);
  }
  copyMessage(val: string) {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
    this.toaster.success('Copied', 'Code copied to clipboard');
  }
}
