<div class="row">
    <div class="col-sm-12">
        <h3>Instructions for showing ads in your email.</h3>
<ol style="padding-left:15px;font-size: 15px;">
    <li>
        Copy the code from this page. 
    </li>
    <li>
        Place this code in your email where you would like it to be seen. Typically this would be at the bottom of your content. 
    </li>
    <li>
        This code will only work with the ESP that you provided us prior to setup. If you wish to send emails thorugh a different ESP, you will need to contact us for new code. 
    </li>
    <li>
        Once this code has been added, please send an email to support@activeemails.com with the Subject Line: Testing Code for Active Emails for (your name or company name)
    </li>

    <li>
        We will verify the ads are working appropriately.
    </li>

    <li>
        Once verified, you are free to use in as many emails as you wish. The more you use it the more you can earn. 
    </li>

    <li>
        Contact us for anything support@ActiveEmails.com
    </li>
</ol>
<div *ngIf="user.esp_code" class="card" style="display: grid;place-content: center;">
    <div class="card-body p-20" style="background-color: #fbe8e9;font-size: 11px;">
        {{user.esp_code}}
    </div>
    <div style="margin-top: 5%;">
        <button (click)="copyMessage(user.esp_code)" value="click to copy" nz-button nzType="primary" >
            <i nz-icon nzType="copy"></i>
            Copy code
          </button>
    </div>
</div>
   

    </div>
</div>